angular.module('managerApp').factory('$_http', function (http) {
    function setReqOptions(options) {
        const _options = {
            headers: {
                'x-webapp-req': 1
            }
        };
        if (options && Object.keys(options).length > 0) {
            if (options.headers) {
                options.headers['x-webapp-req'] = 1;
            } else {
                options.headers = {
                    'x-webapp-req': 1
                };
            }
            return options;
        }
        return _options;
    }
    return {
        get: (url, options) => {
            return http.get(url, setReqOptions(options));
        },
        post: function (url, data, options = {}) {
            return http.post(url, data, setReqOptions(options));
        },
        put: function (url, data, options) {
            return http.put(url, data, setReqOptions(options));
        },
        delete: function (url) {
            return http.delete(url, setReqOptions(null));
        }
    };
});